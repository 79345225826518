<template>
  <b-card class="shadow-sm rounded login-body mb-5 ml-2 mr-2">

    <ErrorPopup :error="error_popup_message" />

    <h3 class="text-center mt-4 mb-4">{{$t('ACCOUNT.LOGIN')}}</h3>

    <form class="login-form" v-on:submit.prevent="onSubmit" v-if="showForm">
      <b-alert
        :show="showWarning"
        variant="danger"
        dismissible
        @dismissed="showWarning = false"
        fade
        >{{$t('ACCOUNT.INVALID_EMAIL_PASSWORD')}}</b-alert
      >
      <b-form-input type="email" @keyup.enter="onSubmit" v-model="form.email" class="mb-8" :placeholder="$t('MEMBER.EMAIL')" />
      <b-form-input type="password" @keyup.enter="onSubmit" v-model="form.password" class="mb-8" :placeholder="$t('MEMBER.PASSWORD')" />
      <div class="d-flex align-items-center justify-content-between mb-8">
        <router-link :to="forgetLink()" tag="a" class="line-link blue-link"
          >{{$t('MEMBER.FORGET_PASSWORD')}}</router-link
        >
        <b-button class="btn-login" variant="primary" @click="onSubmit">{{$t('ACCOUNT.LOGIN')}}</b-button>
      </div>
    </form>
    <div class="warning-message" v-else>
      <b-alert variant="danger" fade show>
        <div class="d-flex align-items-center justify-content-center p-4">
          <div>
            <fa-icon :icon="['far', 'question-circle']" size="3x"></fa-icon>
          </div>
          <div class="ml-4">
            Felaktig inloggningslänk (3). Kontakta din förening för att begära en korrekt länk.
          </div>
        </div>
      </b-alert>
    </div>
  </b-card>
</template>

<style lang="scss" scoped>
  .bg-yellow {
    /*background-color: #FFF8C5;*/
    /*border-color: #ffffff;*/
    /*color: #3F4254;*/
    /*outline: 0;*/
  }

.login-body {
  max-width: 500px;
  width: 100%;
  .login-form {
    padding: 2rem 1.5rem 2rem 1.5rem;
    .btn-login {
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1rem 3rem;
      background-color: #5d78ff;
      border-color: #5d78ff;
      &:hover {
        background-color: #3758ff;
        border-color: #2a4eff;
      }
    }
  }
  .warning-message {
    padding: 30px 20px;
  }
}
</style>

<script>
import ErrorPopup from '@/view/components/ErrorPopup.vue';

import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';


import messageParent from '@/core/services/messageParent';

import { mapState } from 'vuex';
import { MEMBER_LOGIN, MEMBER_LOGOUT, MEMBER_SET_COMPANY, MEMBER_SET_LOGINLINK } from '@/core/services/store/member.auth.module';

import axios from 'axios';

import { validationMixin } from 'vuelidate';
import { email, minLength, required } from 'vuelidate/lib/validators';
import i18nService from "@/core/services/i18n.service.js";


export default {
  mixins: [validationMixin],
  props: ['company_id','redirect'],
  emits: ['on_pending_payment'],
  name: 'login',
  components: {
    ErrorPopup
  },
  data() {
    return {
      error_popup_message: null,
      // Remove this dummy login info
      form: {
        email: '',
        password: '',
        company_id: ''
      },
      showWarning: false,
      showForm: false
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  mounted() {
    if (this.$route.query.lang) {
      const new_lang = this.$route.query.lang;

      if (new_lang.length === 2) {
        if (localStorage.getItem("language") !== new_lang) {
          localStorage.setItem("language", new_lang);
          i18nService.locale = new_lang;
          location.reload();
        }

      }

    }

    console.log('company_id', this.$route.params.company_id);

    this.form.email = getKeyValue('member_login_email');

    if (this.$route.params.company_id) {
      this.form.company_id = this.$route.params.company_id;
      this.$store.dispatch(MEMBER_SET_COMPANY, { company_id: this.form.company_id });
      this.showForm = true;
      const login_link = `/login/${this.$route.params.company_id}`;

      this.$store.dispatch(MEMBER_SET_LOGINLINK, login_link);

    } else {
      if (this.company_id) {
        this.showForm = true;
        this.form.company_id = this.company_id;
        const login_link = `/login/${this.company_id}`;

        this.$store.dispatch(MEMBER_SET_LOGINLINK, login_link);

        this.$store.dispatch(MEMBER_SET_COMPANY, { company_id: this.company_id });
      }
      else {
        const company = this.$store.getters['memberCompany'];

        if (company && company.hasOwnProperty('company_id')) {
          const login_link = `/login/${company.company_id}`;

          this.$store.dispatch(MEMBER_SET_LOGINLINK, login_link);

          this.showForm = true;
          this.form.company_id = company.company_id;
        }
        else {
          this.showForm = false;
        }
      }
    }

    setTimeout(function(){
      messageParent(JSON.stringify({ height: document.body.scrollHeight }));
    }, 300);
  },
  methods: {
    forceCompanyId(company_id) {
      this.showForm = true;
      this.form.company_id = company_id;
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.showWarning = true;
        return;
      }

      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;

      // show loading
      const loader = this.$loading.show();

      setKeyValue('member_login_email', email);

      const res = await axios.post('/member/login', { email, password, company_id: this.form.company_id });

      if (res.status === 409) {
        if (res.data.reason === 'pending_payment') {
          loader && loader.hide();

          this.error_popup_message = 'ERR_PENDING_PAYMENTS_MEMBER';

          this.$nextTick(()=>{ this.error_popup_message = null; });

          this.$emit('on_pending_payment', res.data);
          return;
        }
      }

      if (res.status === 200) {
        // success

        this.$store.dispatch(MEMBER_LOGIN, res.data);
        if (this.redirect) {
          this.$router.push({ name: this.redirect });
        }
        else {
          this.$router.push({ name: 'profile' });
        }
      } else {
        // un authorized
        //this.$func.showTextMessage('Unauthorized', 'Please check your credentials', 'info');
        this.showWarning = true;
      }
      loader && loader.hide();
    },
    forgetLink() {
      return '/forget/' + this.form.company_id;
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
